import Permission_Translate from "./permission.translate";
import Permission_Const from "./permission";
import moment from "moment";
import { message } from "antd";

const accessReq = {
  ACCESS_SETTING: [
    "ACCESS_LVL_WATCH",
    "USER_WATCH",
    "USER_EDITOR",
    "USER_ADMIN",
    "ACCESS_LVL_ADMIN",
  ],

  ACCESSLVL_GETALL: ["ACCESS_LVL_ADMIN", "ACCESS_LVL_WATCH", "USER_ADMIN"],
  ACCESSLVL_CREATE: ["ACCESS_LVL_ADMIN"],
  ACCESSLVL_UPDATE: ["ACCESS_LVL_ADMIN"],

  USER_OWN_UPDATE: ["USER_CURRENT", "USER_WATCH", "USER_EDITOR", "USER_ADMIN"],
  USER_GETALL: ["USER_WATCH", "USER_EDITOR", "USER_ADMIN"],
  USER_UPDATE: ["USER_EDITOR", "USER_ADMIN"],
  USER_CREATE: ["USER_ADMIN"],
  USER_UPDATE_ACCESS: ["USER_ADMIN"],
  USERNAME_PASS_RESET: ["USER_ADMIN"],

  NOTIFICATION_WATCH: ["NOTIFICATION_ADMIN", "NOTIFICATION_WATCH"],
  NOTIFICATION_CREATE: ["NOTIFICATION_ADMIN"],

  ACCESS_REVENUE: ["REVENUE_SET_ADMIN", "REVENUE_SET_WATCH", "REVENUE_REPORT"],

  REVENUE_WATCH: ["REVENUE_SET_ADMIN", "REVENUE_SET_WATCH"],
  REVENUE_EDIT: ["REVENUE_SET_ADMIN"],

  MOBILE_ADS_WATCH: ["MOBILE_ADS_ADMIN", "MOBILE_ADS_WATCH"],
  MOBILE_ADS_ADMIN: ["MOBILE_ADS_ADMIN"],

  REVENUE_REPORT: ["REVENUE_REPORT"],

  FAVOURITE_REPORT: ["FAVOURITE_REPORT"],

  RAW_EVENT_REPORT: ["RAW_EVENT_REPORT"],

  DASHBOARD_TRACK: ["DASHBOARD_TRACK"],

  DASHBOARD_ALBUM: ["DASHBOARD_ALBUM"],

  DASHBOARD_ARTIST: ["DASHBOARD_ARTIST"],

  DASHBOARD_REVENUE: ["DASHBOARD_REVENUE"],

  DASHBOARD_USER: ["DASHBOARD_USER"],

  DASHBOARD_PODCAST: ["DASHBOARD_PODCAST"],

  DASHBOARD_USER_DETAIL: ["DASHBOARD_USER_DETAIL"],

  DASHBOARD_TRACK_DETAIL: ["DASHBOARD_TRACK_DETAIL"],

  TRACK_FAVOURITE: ["TRACK_FAVOURITE"],

  RELEASE_FAVOURITE: ["RELEASE_FAVOURITE"],

  ARTIST_FAVOURITE: ["ARTIST_FAVOURITE"],

  TRACK_REVENUE: ["TRACK_REVENUE"],

  LABEL_GETALL: ["LABEL_ADMIN", "LABEL_EDITOR"],
  LABEL_CREATE: ["LABEL_ADMIN"],
  LABEL_EDIT: ["LABEL_EDITOR", "LABEL_ADMIN"],
  LABEL_DELETE: ["LABEL_ADMIN"],

  AUDIT_GETALL: ["AUDIT_ADMIN", "AUDIT_EDITOR"],
  AUDIT_CREATE: ["AUDIT_ADMIN"],
  AUDIT_EDIT: ["AUDIT_EDITOR", "AUDIT_ADMIN"],
  AUDIT_DELETE: ["AUDIT_ADMIN"],
};

export function reportMessage(data, type) {
  switch (true) {
    case type === "success":
      message.success(data);
      break;
    case type === "warning":
      message.warning(data);
      break;
    default:
      message.error(data);
  }
}

export function permissionCheck(userPermission, reqAccess) {
  if (reqAccess === "FREE") {
    return true;
  }

  if (!userPermission) {
    return false;
  }

  if (!accessReq[reqAccess]) {
    return false;
  }

  const interset = accessReq[reqAccess].filter(
    (permiss) => userPermission.indexOf(permiss) !== -1
  );

  return interset.length > 0;
}

export function permissionTranslation(getPermission) {
  return Permission_Translate[getPermission].value;
}

export function randomColor(getPermission) {
  return Permission_Translate[getPermission].color;
}

export function searchPermission(edit, keyward) {
  const nullValue = keyward === "User" ? "USER_CURRENT" : "none";

  if (!edit) {
    return nullValue;
  }

  if (keyward === "Dashboard") {
    let getPermission = [];

    if (
      edit.permissions.filter((value) => value === "DASHBOARD_TRACK").length > 0
    ) {
      getPermission = [...getPermission, "DASHBOARD_TRACK"];
    }

    if (
      edit.permissions.filter((value) => value === "DASHBOARD_ALBUM").length > 0
    ) {
      getPermission = [...getPermission, "DASHBOARD_ALBUM"];
    }

    if (
      edit.permissions.filter((value) => value === "DASHBOARD_ARTIST").length >
      0
    ) {
      getPermission = [...getPermission, "DASHBOARD_ARTIST"];
    }

    if (
      edit.permissions.filter((value) => value === "DASHBOARD_REVENUE").length >
      0
    ) {
      getPermission = [...getPermission, "DASHBOARD_REVENUE"];
    }

    if (
      edit.permissions.filter((value) => value === "DASHBOARD_USER").length > 0
    ) {
      getPermission = [...getPermission, "DASHBOARD_USER"];
    }

    if (
      edit.permissions.filter((value) => value === "DASHBOARD_PLAY_LIST")
        .length > 0
    ) {
      getPermission = [...getPermission, "DASHBOARD_PLAY_LIST"];
    }

    if (
      edit.permissions.filter((value) => value === "DASHBOARD_FAVOURITE")
        .length > 0
    ) {
      getPermission = [...getPermission, "DASHBOARD_FAVOURITE"];
    }

    if (
      edit.permissions.filter((value) => value === "DASHBOARD_LIKE").length > 0
    ) {
      getPermission = [...getPermission, "DASHBOARD_LIKE"];
    }

    if (
      edit.permissions.filter((value) => value === "DASHBOARD_EVENT").length > 0
    ) {
      getPermission = [...getPermission, "DASHBOARD_EVENT"];
    }

    return getPermission;
  }

  if (keyward === "DataView") {
    let getPermission = [];

    if (
      edit.permissions.filter((value) => value === "TRACK_FAVOURITE").length > 0
    ) {
      getPermission = [...getPermission, "TRACK_FAVOURITE"];
    }

    if (
      edit.permissions.filter((value) => value === "RELEASE_FAVOURITE").length >
      0
    ) {
      getPermission = [...getPermission, "RELEASE_FAVOURITE"];
    }

    if (
      edit.permissions.filter((value) => value === "ARTIST_FAVOURITE").length >
      0
    ) {
      getPermission = [...getPermission, "ARTIST_FAVOURITE"];
    }

    if (
      edit.permissions.filter((value) => value === "TRACK_REVENUE").length > 0
    ) {
      getPermission = [...getPermission, "TRACK_REVENUE"];
    }

    return getPermission;
  }

  const getPermission = Permission_Const[keyward].filter(
    (permiss) => edit.permissions.indexOf(permiss) !== -1
  );

  return getPermission.length > 0 ? getPermission[0] : nullValue;
}

export function timeFormat(getDate) {
  return moment(getDate).format("YYYY-MM-DD HH:mm:ss");
}

export function secondToTime(getSeconds) {
  return `${Math.floor(
    moment.duration(getSeconds, "seconds").asHours()
  )} Hr, ${moment.duration(getSeconds, "seconds").minutes()} Min, ${moment
    .duration(getSeconds, "seconds")
    .seconds()} Sec`;
}

export function secondToTrackTime(getSeconds) {
  return moment.utc(getSeconds).format("mm:ss");
}

export function tagColorsByIndex(value) {
  const colors = ["magenta", "purple", "volcano", "blue", "green"];

  switch (true) {
    case value % 5 == 0:
      return colors[4];
    case value % 4 == 0:
      return colors[3];
    case value % 3 == 0:
      return colors[2];
    case value % 2 == 0:
      return colors[1];
    case value % 1 == 0:
      return colors[0];
    default:
      return colors[4];
  }
}

export function numberFormat(value) {
  return value.toLocaleString("en-US", { minimumFractionDigits: 2 });
}

export function countNumberFormat(value) {
  return value.toLocaleString("en-US", { minimumFractionDigits: 0 });
}

export function fakeUserData(value) {}
