import axios from "axios";
import linkCost from "./linkConst";

function requestHeader(access_token) {
  return { headers: { Authorization: `Bearer ${access_token}` } };
}

function generateurl(link) {
  return `${linkCost.DEFAULT_URL}${link}`;
}

// ness

export function login(body) {
  return axios.post(generateurl("login"), body);
}

function accessLvlGet(link, access_token) {
  return axios.get(generateurl(link), requestHeader(access_token));
}

function accessLvlCreate(link, access_token, data) {
  return axios.post(generateurl(link), data, requestHeader(access_token));
}

function accessLvlUpdate(link, access_token, data) {
  return axios.post(generateurl(link), data, requestHeader(access_token));
}

function userGetAll(link, access_token) {
  return axios.get(generateurl(link), requestHeader(access_token));
}

function userCreate(link, access_token, data) {
  return axios.post(generateurl(link), data, requestHeader(access_token));
}

function userPasswordRewst(link, access_token, data) {
  return axios.post(generateurl(link), data, requestHeader(access_token));
}

function userUpdateByAdmin(link, access_token, data) {
  return axios.post(generateurl(link), data, requestHeader(access_token));
}

function userNormalUpdate(link, access_token, data) {
  return axios.post(generateurl(link), data, requestHeader(access_token));
}

function getRawEventList(link, access_token) {
  return axios.get(generateurl(link), requestHeader(access_token));
}

function getMusicUserList(link, access_token, query) {
  return axios.get(
    `${generateurl(link)}?${query}`,
    requestHeader(access_token)
  );
}

function getNotiList(link, access_token, query) {
  return axios.get(
    `${generateurl(link)}?${query}`,
    requestHeader(access_token)
  );
}
function getTopicList(link, access_token) {
  return axios.get(generateurl(link), requestHeader(access_token));
}

function sendNotification(link, access_token, data) {
  return axios.post(generateurl(link), data, requestHeader(access_token));
}

function getTrackListApi(link, access_token, query) {
  return axios.get(
    `${generateurl(link)}?${query}`,
    requestHeader(access_token)
  );
}

function imageUploadApi(link, access_token, file) {
  const formData = new FormData();
  formData.append("file", file);

  return axios.post(generateurl(link), formData, {
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "multipart/form-data",
    },
  });
}

function notiRefrenceSearch(link, access_token, query) {
  return axios.get(
    `${generateurl(link)}?${query}`,
    requestHeader(access_token)
  );
}

function get20TracksByNameSearch(link, access_token, key) {
  return axios.get(`${generateurl(link)}/${key}`, requestHeader(access_token));
}

function get20AlbumByNameSearch(link, access_token, key) {
  return axios.get(`${generateurl(link)}/${key}`, requestHeader(access_token));
}

function get20ArtistByNameSearch(link, access_token, key) {
  return axios.get(`${generateurl(link)}/${key}`, requestHeader(access_token));
}

function get20GenraByNameSearch(link, access_token, key) {
  return axios.get(`${generateurl(link)}/${key}`, requestHeader(access_token));
}

function get20TagByNameSearch(link, access_token, key) {
  return axios.get(`${generateurl(link)}/${key}`, requestHeader(access_token));
}

function get20LabelByNameSearch(link, access_token, key) {
  return axios.get(`${generateurl(link)}/${key}`, requestHeader(access_token));
}

function getLabelListForArtistDashboard(link, access_token) {
  return axios.get(`${generateurl(link)}`, requestHeader(access_token));
}

function artistDashboardCreateLabel(link, access_token, data) {
  return axios.post(`${generateurl(link)}`, data, requestHeader(access_token));
}

function artistDashboardLoginNameCheck(link, access_token) {
  return axios.get(`${generateurl(link)}`, requestHeader(access_token));
}

function deleteArtistDashboard(link, access_token, id) {
  return axios.patch(
    `${generateurl(link)}/${id}`,
    {},
    requestHeader(access_token)
  );
}

function resetPasswordArtistDashboard(link, access_token, data) {
  return axios.patch(`${generateurl(link)}`, data, requestHeader(access_token));
}

export {
  accessLvlGet,
  accessLvlCreate,
  accessLvlUpdate,
  userGetAll,
  userCreate,
  userPasswordRewst,
  userUpdateByAdmin,
  userNormalUpdate,
  getRawEventList,
  getMusicUserList,
  getNotiList,
  getTopicList,
  sendNotification,
  getTrackListApi,
  imageUploadApi,
  notiRefrenceSearch,
  get20TracksByNameSearch,
  get20AlbumByNameSearch,
  get20ArtistByNameSearch,
  get20GenraByNameSearch,
  get20TagByNameSearch,
  get20LabelByNameSearch,
  getLabelListForArtistDashboard,
  artistDashboardCreateLabel,
  artistDashboardLoginNameCheck,
  deleteArtistDashboard,
  resetPasswordArtistDashboard,
};

// old

export function getApiNOId(link, access_token) {
  console.log(`${linkCost.DEFAULT_URL}` + link);
  return axios.get(`${linkCost.DEFAULT_URL}` + link, {
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });
}

export function getApi(link, access_token, userId) {
  return axios.get(`${linkCost.DEFAULT_URL}` + link + `/${userId}`, {
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });
}

export function getApiWithTwoValue(link, access_token, userId, value1, value2) {
  return axios.get(
    `${linkCost.DEFAULT_URL}` + link + `/${userId}/${value1}/${value2}`,
    {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    }
  );
}

export function getApiWith1Filter(link, access_token, userId, filter1) {
  return axios.get(`${linkCost.DEFAULT_URL}` + link + `/${userId}/${filter1}`, {
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });
}

export function getApiWithData(link, access_token, userId, Data) {
  return axios.get(
    `${linkCost.DEFAULT_URL}` + link + `/${userId}` + `/${Data}`,
    {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    }
  );
}

export function normalPostApiUPdate(link, access_token, body, userId) {
  return axios.post(`${linkCost.DEFAULT_URL}` + link + `/${userId}`, body, {
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });
}

export function postApiCreate(link, access_token, body, userId) {
  return axios.post(`${linkCost.DEFAULT_URL}` + link + `/${userId}`, body, {
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });
}

export function postApiUpdate(link, access_token, body, id, userId) {
  return axios.post(
    `${linkCost.DEFAULT_URL}` + id + link + `/${userId}`,
    body,
    {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    }
  );
}

export function getPaginationSearch(
  link,
  access_token,
  userId,
  currentPage,
  perPage,
  search
) {
  return axios.get(
    `${linkCost.DEFAULT_URL}` +
      link +
      `/${userId}/${currentPage}/${perPage}/${search}`,
    {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    }
  );
}

export function getPaginationSearchNotification(
  link,
  access_token,
  userId,
  currentPage,
  perPage,
  search,
  type
) {
  return axios.get(
    `${linkCost.DEFAULT_URL}` +
      link +
      `/${userId}/${currentPage}/${perPage}/${search}/${type}`,
    {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    }
  );
}

export function getPagination(link, access_token, userId, pageNumber, perPage) {
  return axios.get(
    `${linkCost.DEFAULT_URL}` + link + `/${userId}/${pageNumber}/${perPage}`,
    {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    }
  );
}

export function getPaginationSearchWithTwoFilters(
  link,
  access_token,
  userId,
  currentPage,
  perPage,
  filter1
) {
  return axios.get(
    `${linkCost.DEFAULT_URL}` +
      link +
      `/${userId}/${currentPage}/${perPage}/${filter1}`,
    {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    }
  );
}

export function UpdateRevenue(link, access_token, userId, body) {
  return axios.post(`${linkCost.DEFAULT_URL}` + link + `/${userId}`, body, {
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });
}

export function dataUsageCsvUpload(link, access_token, userId, file, body) {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("name", body.name);
  formData.append("target_month", body.target_month);
  formData.append("target_year", body.target_year);
  formData.append("target_type_id", body.target_type_id);
  formData.append("comment", body.comment);

  return axios.post(`${linkCost.DEFAULT_URL}` + link + `/${userId}`, formData, {
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "multipart/form-data",
    },
  });
}

export function imageUpload(link, access_token, userId, file) {
  const formData = new FormData();
  formData.append("file", file);

  return axios.post(`${linkCost.DEFAULT_URL}${link}/${userId}`, formData, {
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "multipart/form-data",
    },
  });
}

export function getRevenueByDate(
  link,
  access_token,
  userId,
  startDate,
  endDate,
  filter1
) {
  return axios.get(
    `${linkCost.DEFAULT_URL}` +
      link +
      `/${userId}/${startDate}/${endDate}/${filter1}`,
    {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    }
  );
}

export function getFavouriteByDate(
  link,
  access_token,
  userId,
  pageNumber,
  perPage
) {
  return axios.get(
    `${linkCost.DEFAULT_URL}` + link + `/${userId}/${pageNumber}/${perPage}`,
    {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    }
  );
}

export function getApiWithQuery(link, userId, access_token, query) {
  return axios.get(`${linkCost.DEFAULT_URL}` + link + `/${userId}?${query}`, {
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });
}
