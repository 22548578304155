import React, { Suspense } from "react";
import { Layout, Spin } from "antd";
import { Redirect, Route, Switch } from "react-router-dom";

const { Content } = Layout;

const Dashboard = React.lazy(() => import("../components/dashboard/general"));
const DashboardAlbum = React.lazy(() =>
  import("../components/dashboard/dashboard_album/index")
);
const DashboardArtist = React.lazy(() =>
  import("../components/dashboard/dashboard_artist/index")
);
const DashboardPodcast = React.lazy(() =>
  import("../components/dashboard/dashboard_podcast/index")
);
const DashboardLike = React.lazy(() =>
  import("../components/dashboard/dashboard_like/index")
);
const DashboardPlaylist = React.lazy(() =>
  import("../components/dashboard/dashboard_playlist/index")
);
const DashboardRevenue = React.lazy(() =>
  import("../components/dashboard/dashboard_revenue/index")
);
const DashboardTrack = React.lazy(() =>
  import("../components/dashboard/dashboard_track/index")
);
const DashboardUser = React.lazy(() =>
  import("../components/dashboard/dashboard_user/index")
);
const DashboardEvent = React.lazy(() =>
  import("../components/dashboard/dashboard_event/index")
);
const DashboardSubscription = React.lazy(() =>
  import("../components/dashboard/dashboard_subscription/index")
);
const DashboardUserDetail = React.lazy(() =>
  import("../components/dashboard/dashboard_user_detail")
);
const DashboardTrackDetail = React.lazy(() =>
  import("../components/dashboard/dashboard_track_detail")
);
const EventReport = React.lazy(() => import("../components/eventReport/index"));
const FavouriteAlbum = React.lazy(() =>
  import("../components/favourite/favourite_album/index")
);
const FavouriteArtist = React.lazy(() =>
  import("../components/favourite/favourite_artist/index")
);
const FavouritePlayList = React.lazy(() =>
  import("../components/favourite/favourite_play_list/index")
);
const FavouriteTrack = React.lazy(() =>
  import("../components/favourite/favourite_track/index")
);
const RevenueIncome = React.lazy(() =>
  import("../components/revenue/revenue_income/index")
);
const RevenueList = React.lazy(() =>
  import("../components/revenue/revenue_list/index")
);
const UploadDataUsage = React.lazy(() =>
  import("../components/revenue/upload_data_usage/index")
);
const RevenueReport = React.lazy(() =>
  import("../components/revenue/revenue_report/index")
);
const RevenueSetLabel = React.lazy(() =>
  import("../components/revenue/revenue_set_label/index")
);
const RevenueType = React.lazy(() =>
  import("../components/revenue/revenue_type/index")
);
const SettingAccessManagement = React.lazy(() =>
  import("../components/setting/accessManagement/index")
);
const SettingUserManagement = React.lazy(() =>
  import("../components/setting/userManagement/index")
);
const Notification = React.lazy(() =>
  import("../components/notification/index")
);
const MobileAds = React.lazy(() => import("../components/mobileAds/index"));
const LabelDashboard = React.lazy(() =>
  import("../components/labelDashboard/index")
);

const AuditDashboard = React.lazy(() =>
  import("../components/auditDashboard/index")
);

export default function BodyRoutes() {
  function loading() {
    return (
      <Spin tip="Loading..." size="large">
        <div style={{ width: "100vw", height: "100vh" }}></div>
      </Spin>
    );
  }

  return (
    <Content style={{ overflowY: "auto" }}>
      <Suspense fallback={() => loading()}>
        <Switch>
          <Route path="/" name="Home" exact={true} component={Dashboard} />
          <Route
            path="/dashboard_general"
            name="dashboard_general"
            component={Dashboard}
          />
          <Route
            path="/label_dashboard"
            name="label_dashboard"
            component={LabelDashboard}
          />
          <Route
            path="/audit_dashboard"
            name="audit_dashboard"
            component={AuditDashboard}
          />
          <Route
            path="/dashboard_track"
            name="dashboard_track"
            component={DashboardTrack}
          />
          <Route
            path="/dashboard_album"
            name="dashboard_album"
            component={DashboardAlbum}
          />
          <Route
            path="/dashboard_artist"
            name="dashboard_artist"
            component={DashboardArtist}
          />
          <Route
            path="/dashboard_revenue"
            name="dashboard_revenue"
            component={DashboardRevenue}
          />
          <Route
            path="/dashboard_user"
            name="dashboard_user"
            component={DashboardUser}
          />
          <Route
            path="/dashboard_playlist"
            name="dashboard_playlist"
            component={DashboardPlaylist}
          />
          <Route
            path="/dashboard_podcast"
            name="dashboard_podcast"
            component={DashboardPodcast}
          />
          <Route
            path="/dashboard_like"
            name="dashboard_like"
            component={DashboardLike}
          />
          <Route
            path="/dashboard_event"
            name="dashboard_event"
            component={DashboardEvent}
          />
          <Route
            path="/dashboard_subscription"
            name="dashboard_subscription"
            component={DashboardSubscription}
          />
          <Route
            path="/dashboard_user_detail"
            name="dashboard_user_detail"
            component={DashboardUserDetail}
          />
          <Route
            path="/dashboard_track_detail"
            anme="dashboard_track_detail"
            component={DashboardTrackDetail}
          />
          <Route
            path="/data_view_favourite_track"
            name="favourite_track"
            component={FavouriteTrack}
          />
          <Route
            path="/data_view_favourite_album"
            name="favourite_album"
            component={FavouriteAlbum}
          />
          <Route
            path="/data_view_favourite_artist"
            name="favourite_artist"
            component={FavouriteArtist}
          />
          <Route
            path="/data_view_revenue_track"
            name="favourite_play_list"
            component={FavouritePlayList}
          />
          <Route
            path="/revenue_type"
            name="revenue_type"
            component={RevenueType}
          />
          <Route
            path="/revenue_list"
            name="revenue_list"
            component={RevenueList}
          />
          <Route
            path="/revenue_label"
            name="revenue_label"
            component={RevenueSetLabel}
          />
          <Route
            path="/upload_data_usage"
            name="upload_data_usage"
            component={UploadDataUsage}
          />
          <Route
            path="/revenue_income"
            name="revenue_income"
            component={RevenueIncome}
          />
          <Route
            path="/revenue_report"
            name="revenue_report"
            component={RevenueReport}
          />
          <Route
            path="/setting_user_management"
            name="setting_user_management"
            component={SettingUserManagement}
          />
          <Route
            path="/setting_access_lvl_management"
            name="setting_access_lvl_management"
            component={SettingAccessManagement}
          />
          <Route
            path="/notification"
            name="notification"
            component={Notification}
          />
          <Route
            path="/raw_event_report"
            name="raw_event_report"
            component={EventReport}
          />
          <Route path="/mobile_ads" name="mobile_ads" component={MobileAds} />
          <Redirect from="/" to="/dashboard_general" />
        </Switch>
      </Suspense>
    </Content>
  );
}
