const Permission_Const = {
  User: ["USER_ADMIN", "USER_EDITOR", "USER_WATCH", "USER_CURRENT"],
  Access: ["ACCESS_LVL_ADMIN", "ACCESS_LVL_WATCH", "none"],
  Revenue: ["REVENUE_SET_ADMIN", "REVENUE_SET_WATCH", "none"],
  RevenueReport: ["REVENUE_REPORT", "none"],
  Favourite: ["FAVOURITE_REPORT", "none"],
  RawEvent: ["RAW_EVENT_REPORT", "none"],
  // MobileAds:['MOBILE_ADS_ADMIN','MOBILE_ADS_WATCH','none'],
  Dashboard: [
    "DASHBOARD_TRACK",
    "DASHBOARD_ALBUM",
    // 'DASHBOARD_ARTIST',
    "DASHBOARD_REVENUE",
    "DASHBOARD_USER",
    "DASHBOARD_USER_DETAIL",
    "DASHBOARD_TRACK_DETAIL",
    //  'DASHBOARD_PODCAST'
  ],
  DataView: [
    "TRACK_FAVOURITE",
    "RELEASE_FAVOURITE",
    "ARTIST_FAVOURITE",
    "TRACK_REVENUE",
  ],
  Notification: ["NOTIFICATION_ADMIN", "NOTIFICATION_WATCH", "none"],
  ReleaseLabelDashboard: ["LABEL_ADMIN", "LABEL_EDITOR", "none"],
  AuditDashboard: ["AUDIT_ADMIN", "AUDIT_EDITOR", "none"],
};

export default Permission_Const;
