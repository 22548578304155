const Permission_Translate = {
  none: {
    value: "No permission",
    color: "gray",
  },

  // label
  LABEL_ADMIN:{
    // (create , update,delete ) all fields
    value: "Full Access to LabelDashboard management",
    color: "blue",
  },
  LABEL_EDITOR:{
    // create,update
    value: "Access to Watch and Refresh Label Login Name and Password",
    color: "gold",
  },

  //User
  USER_ADMIN: {
    // (create , update,delete ) all fields
    value: "Full Access to Users management",
    color: "blue",
  },
  USER_EDITOR: {
    // create,update
    value: "Access to Update Users data (minimal)",
    color: "gold",
  },
  USER_WATCH: {
    // watch all users
    value: "Access to Can view all users list",
    color: "red",
  },
  USER_CURRENT: {
    // own user update ( without access lvl )
    value: "Only can change Own Username and Password",
    color: "green",
  },

  // notification

  NOTIFICATION_ADMIN: {
    // (create/ update /delete) all fields
    value: "Full Access to Notification Management",
    color: "blue",
  },

  NOTIFICATION_WATCH: {
    // watch all notifications
    value: "Access to Watch Notification Management",
    color: "orange",
  },

  //Access Lvl
  ACCESS_LVL_ADMIN: {
    // (create, update)
    value: "Access Create/Update AccessLvl",
    color: "blue",
  },
  ACCESS_LVL_WATCH: {
    // watch all users
    value: "Access only watch AccessLvl list",
    color: "orange",
  },

  //Revenue set
  REVENUE_SET_ADMIN: {
    value: "Access to Edit revenue data",
    color: "blue",
  },
  REVENUE_SET_WATCH: {
    value: "Access to only watch revenue details",
    color: "orange",
  },

  //mobile ads
  MOBILE_ADS_ADMIN: {
    value: "Access to CRUD of Ads",
    color: "blue",
  },

  MOBILE_ADS_WATCH: {
    value: "Access to only watch mobile ads list",
    color: "orange",
  },

  // Revenue report
  REVENUE_REPORT: {
    value: "Access to watch Revenue List and get Report",
    color: "blue",
  },

  //Favourite
  FAVOURITE_REPORT: {
    value: "Access to watch Favourite List and get Report",
    color: "blue",
  },

  //Watch Raw Event
  RAW_EVENT_REPORT: {
    value: "Access to watch Raw Events",
    color: "blue",
  },

  // Dashboard Track
  DASHBOARD_TRACK: {
    value: "Access to watch analysics Tracks",
    color: "purple",
  },

  // Dashboard Album
  DASHBOARD_ALBUM: {
    value: "Access to watch analysics Album & Artist",
    color: "purple",
  },

  // Dashboard Artist
  DASHBOARD_ARTIST: {
    value: "Access to watch analysics Artist",
    color: "purple",
  },

  // Dashboard Revenue
  DASHBOARD_REVENUE: {
    value: "Access to watch analysics Revenue",
    color: "purple",
  },

  // Dashboard User
  DASHBOARD_USER: {
    value: "Access to watch analysics User",
    color: "purple",
  },

  // Dashboard PODCAST
  DASHBOARD_PODCAST: {
    value: "Access to watch analysics Podcast",
    color: "purple",
  },

  // Dashboard User Detail
  DASHBOARD_USER_DETAIL: {
    value: "Access to watch music user detail page",
    color: "purple",
  },

  //Dashboard Track Detail
  DASHBOARD_TRACK_DETAIL: {
    value: "Access to watch Track detail page",
    color: "purple",
  },

  // DataView Track FAvourite
  TRACK_FAVOURITE: {
    value: "Access to watch Track Favourite",
    color: "magenta",
  },

  // DataView Release Favourite
  RELEASE_FAVOURITE: {
    value: "Access to watch Album Favourite",
    color: "magenta",
  },

  // DataView Artist Favourite
  ARTIST_FAVOURITE: {
    value: "Access to watch Artist Favourite",
    color: "magenta",
  },

  // DataView track revenue
  TRACK_REVENUE: {
    value: "Access to watch Revenue Track Data",
    color: "magenta",
  },

  // Lable Dashboard Admin
  LABEL_ADMIN:{
    value:"Access to CRUD Lable login",
    color:"blue"
  },

  // Lable Dashboard Editor
  LABEL_EDITOR:{
    value:"Access to watch and Refresh login",
    color:"orange"
  },
  
  // Audit Dashboard Admin
  AUDIT_ADMIN:{
    value:"Access to CRUD Audit login",
    color:"blue"
  },

  // Audit Dashboard Editor
  AUDIT_EDITOR:{
    value:"Access to watch and Refresh login",
    color:"orange"
  },
};

export default Permission_Translate;

// Revenue:['REVENUE_SET_ADMIN','REVENUE_SET_WATCH'],
// RevenueReport:['REVENUE_REPORT']
